import React from "react"
import styled from 'styled-components'

//Helpers
import Title from "../helpers/Title"

//Components
import Layout from "../components/Layout"
import Seo from "../components/seo"
import Language from "../components/Language"
import LogoSection from "../components/LogoSection"

//Content
import texts, { publishers } from "../components/Publishers/content"

const Publishers = () => (
  <Layout>
    <Seo title="Publishers" />
    <Container>
      <Title>{Language(texts).countdown}</Title>
      <LogoSection
        title="Publishers"
        list={publishers}
      />
    </Container>
  </Layout>
)

export default Publishers

const Container = styled.section`
  padding-top: 2rem;
  margin-bottom: 4rem;
`