import poki from '../../assets/images/logos/publishers/poki.png'
import nuuvem from '../../assets/images/logos/publishers/nuuvem.png'
import g4c from '../../assets/images/logos/publishers/g4c.png'
import gazeus from '../../assets/images/logos/publishers/gazeus.png'
import gameRound from '../../assets/images/logos/publishers/gameRound.png'
import dangen from '../../assets/images/logos/publishers/dangen.png'
import voodoo from '../../assets/images/logos/publishers/voodoo.png'
import bemobi from '../../assets/images/logos/patrocinadores/bemobi.png'
import hermitCrab from '../../assets/images/logos/patrocinadores/hermitCrab.png'
import moonee from '../../assets/images/logos/publishers/moonee.svg'

export const publishers = [
  {img: poki, link: "https://poki.com/"},
  {img: nuuvem, link: "https://www.nuuvem.com/"},
  {img: g4c, link: "http://www.gamesforchange.org/"},
  {img: gazeus, link: "https://www.gazeus.com/"},
  {img: gameRound, link: "https://gameround.co/"},
  {img: dangen, link: "https://dangenentertainment.com/"},
  {img: voodoo, link: "https://www.voodoo.io/"},
  {img: bemobi, link: "https://www.bemobi.com.br/"},
  {img: hermitCrab, link: "https://hermitcrabstudio.com/"},
  {img: moonee, link: "https://moonee.io/"},
]

const Texts = {
  PT: {
    countdown: `Novidades em breve!`,
  },
  EN: {
    countdown: `News soon!`,

  }
}

export default Texts