import React from "react"
import styled from "styled-components"

//Helpers
import Title from "../../helpers/Title"

const LogoSection = ({ title, list }) => (
  <LogoSectionContainer>
    <LogoSectionTitle>{title}</LogoSectionTitle>
    <LogoSectionContent>
      {list.map((item, index) => {
        return (
          <LogoSectionLink
            href={item.link !== "" ? item.link : "#"}
            target={item.link !== "" ? "_blank" : "_self"}
            alt=""
            key={index}
          >
            <LogoSectionImg src={item.img} alt="logo" />
          </LogoSectionLink>
        )
      })}
    </LogoSectionContent>
  </LogoSectionContainer>
)

export default LogoSection

const LogoSectionContainer = styled.section`
  width: 100%;
  max-width: 1240px;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4rem auto;
`
const LogoSectionTitle = styled(Title)`
  margin-bottom: 0;
`
const LogoSectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 0 4rem;
`
const LogoSectionLink = styled.a`
  width: 180px;
  margin: 24px;
`
const LogoSectionImg = styled.img`
  width: 100%;
`
